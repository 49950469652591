<template>
  <div>
    <b-row v-if="initData != null">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Purchase Overview</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="target_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Target Quantity"
        >
          <b-form-input
            id="target_quantity"
            v-model="initData.qty"
            name="target_quantity"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="to_be_purchased"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="To be purchased"
        >
          <b-form-input
            id="to_be_purchased"
            v-model="initData.not_purchased_qty"
            name="to_be_purchased"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="purchasing_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Purchasing Quantity"
        >
          <b-form-input
            id="purchasing_quantity"
            v-model="initData.purchasing_qty"
            name="purchasing_quantity"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="purchased_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Purchased Quantity"
        >
          <b-form-input
            id="purchased_quantity"
            v-model="initData.purchased_qty"
            name="purchased_quantity"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-row>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
// import ReferenceLink from '@/components/ReferenceLink/Index';
// import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'PurchaseoOverviewInformationPurchaseOverview',

  data() {
    return {};
  },
  props: {
    initData: Object,
    options: Object
  },
  inheritAttrs: false,
  components: {
    // ReferenceLink
    // SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image {
  width: 100%;
}
</style>
